<script lang="ts" setup>
const emit = defineEmits(['enter', 'leave'])
const props = defineProps({
    threshold: { 
        type: Number, 
        required: false 
    },
    rootMargin: {
        type: String,
        required: false
    }
}); 
const intersecting = ref(false)
const container = ref(null)

if (process.client) {
    const observer = new IntersectionObserver(
        ([entry]) => {
            intersecting.value = entry.isIntersecting;
            if(entry.isIntersecting){
                emit('enter')
            }else{
                emit('leave')
            }
            //console.log(`container (${props.debugName})intersecting`,  intersecting.value)
        },
        {
            threshold: props.threshold || 1.0, 
            rootMargin: props.rootMargin
        }
    );
    onMounted(() => {
        observer.observe(container.value);
    });
}

</script>
<template>
    <div ref="container" class="bg-transparent" >
         <slot></slot>
    </div>
</template>
